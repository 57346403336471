import React from 'react'
import classes from './ProjectDetailsForm.module.css'
import {pageDefaultHeight} from './../../common/sizes'
import UserInfoArea from './../../common/UserInfoArea/UserInfoArea'
import {ReactComponent as GoBackIcon} from './../../../img/icons/goBackIcon.svg'
import ContactsTable from './ContactsTable'
import CustomDivider from './../../common/CustomDivider/CustomDivider'
import MainInfoForm from './MainInfoForm'
import { Link } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../common/buttons/buttons'
import { getCurrentUserRoles } from '../../../helpers/localStorage'
import {useNavigate} from "react-router";

const ProjectDetailsForm = (props) => {
  const navigate = useNavigate()
  const currentUserRoles = getCurrentUserRoles()
  const isUser = currentUserRoles.length === 1 && currentUserRoles?.some(role => role.code.toLowerCase() === 'user')
  
  if (props.modalView) {
    return (
      <>
        <MainInfoForm 
          dataForEditing={props.dataForEditing}
          isDataSent={props.isDataSent}
          setIsDataSent={props.setIsDataSent}
          error={props.error}
          setError={props.setError}
          setFormData={props.setFormData}
          formData={props.formData}
          modalView={props.modalView}
          cancelModalView={props.cancelModalView}
        />
        <div className={classes.buttonsArea} style={{width: '400px', marginTop: '20px'}}>
          <SecondaryButton
            style={{width:'100px', height: 'auto'}}
            text='Cancel' 
            onClick={() => props.cancelModalView()}
          />
          <PrimaryButton 
            className={classes.primaryButton}    
            onClick={props.onSubmit}
            style={{width:'100px', height: 'auto', marginLeft: '15px'}}
            text='SAVE' 
          />
        </div>
      </>
    )
  }
  
  return (
    <>
      <header className={classes.header}>
        <div className={classes.pageTitle}>
          <GoBackIcon 
            onClick={() => navigate(`/Projects`)}
            style={{cursor: 'pointer', marginRight: '10px'}}
          /> 
          {props.dataForEditing ? props.dataForEditing?.name.toUpperCase() : 'NEW PROJECT'}
        </div>
        <UserInfoArea />
      </header>
      <div style={{height: pageDefaultHeight}} className={classes.contentWrapper}>
        <MainInfoForm 
          dataForEditing={props.dataForEditing}
          isDataSent={props.isDataSent}
          setIsDataSent={props.setIsDataSent}
          error={props.error}
          setError={props.setError}
          setFormData={props.setFormData}
          formData={props.formData}
        />
        {props.dataForEditing && !isUser &&
          <>
            <CustomDivider 
              text='Contact List' 
              textStyle={{fontSize:'14px', fontWeight:600, width:"100px", color:'#0E9AFC'}}
            />
            <ContactsTable
              organizations={props.organizations}
              changeContactData={props.changeContactData}
              tableMessage={props.tableMessage}
              isStakeholdersLoading={props.isStakeholdersLoading}
            />
          </>
        }
        <div className={classes.buttonsArea} style={{width: props.dataForEditing ? '100%' : '705px'}}>
          <Link to='/Projects'>
            <SecondaryButton
              style={{width:'100px', height: 'auto'}}
              text='Cancel' 
            />
          </Link>
          <PrimaryButton 
            className={classes.primaryButton}    
            onClick={props.onSubmit}
            style={{width:'100px', height: 'auto', marginLeft: '15px'}}
            text='SAVE' 
          />
        </div>
      </div>
    </>
  )
}

export default ProjectDetailsForm
