import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectsByPeriod } from '../../../../../store/projectsReducer'
import Projects from './Projects'

const ProjectsContainer = (props) => {
  const dispatch = useDispatch()
  const [modalData, setModalData] = useState(false)
  const [tableRows, setTableRows] = useState([])
  const [error, setError] = useState(null)
  const projects = useSelector(state => state.projectsReducer.projectList)
  const allProjects = useSelector(state => state.projectsReducer.projectsAllPeriods)
  const userId = useSelector(store => store.authReducer.userId)

  useEffect(() => {
    !!userId && dispatch(getProjectsByPeriod(null, false, userId))
  }, [dispatch, userId])

  useEffect(() => {
    const getProjectName = (id) => {
      return allProjects.find(project => project.projId === id)?.name
    }

    props.values?.projectRelations 
      ? setTableRows(props.values.projectRelations.map(proj => ({...proj, projectName: getProjectName(proj.projId), id: proj.projId}))) 
      : setTableRows([])
  }, [props.values, projects, allProjects])

  const getSelectProjectOptions = () => {
    const projectOptions = projects.map(project => ({id: project.projId, title: project.name, value: project.projId, name: project.name}))
    const placeholderOption = {id: 'placeholderOption', value: 'none', title: 'Choose a project', disabled: true}
    const sortedProjectOptions = _.orderBy(projectOptions, [project => project.name.toLowerCase()], ['asc'])
    return [placeholderOption, ...sortedProjectOptions]
  }

  const removeProject = (id) => {
    const updatedProjects = props.values.projectRelations.filter(project => project.projId !== id)
    props.setFieldValue('projectRelations', updatedProjects)
  }

  const updatedProjectNumberValue = (projId, valueKey, value) => {
    const updatedProjects = props.values.projectRelations.map((project => {
      if (project.projId === projId) {
        delete project.id
        return {...project, [valueKey]: value}
      } else {
        return project
      }
    }))
    props.setFieldValue('projectRelations', updatedProjects)
  }

  return (
    <Projects 
      error={error}
      modalData={modalData}
      projects={getSelectProjectOptions()}
      setModalData={setModalData}
      setFieldValue={props.setFieldValue}
      setError={setError}
      tableValues={tableRows}
      values={props.values}
      removeProject={removeProject}
      setHasProjectChanges={props.setHasProjectChanges}
      updatedProjectNumberValue={updatedProjectNumberValue}
    />
  )
}

export default ProjectsContainer
