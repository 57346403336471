import { Modal } from 'antd'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import ProjectDetailsFormContainer from '../../Projects/ProjectDetailsForm/ProjectDetailsFormContainer'
import { default as CustomSelect } from '../Select/Select'
import classes from './ProjectSelect.module.css'

const ProjectSelect = ({options, onSelect, value, style, className, disabled, showCompleted}) => {
  const isMobileScreen = useMediaQuery({ query: '(min-width: 360px) and (max-width: 766px)' })
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <CustomSelect
        options={options}
        onSelect={onSelect}
        style={style}
        value={value}
        className={className}
        disabled={disabled}
        groupedOptions={showCompleted}
        dropdownRender={(menu) => (
          <>
            {menu}
            {!isMobileScreen &&
              <div className={classes.addProjectBtn} onClick={() => setIsOpen(true)}>
                + Add New
              </div>
            }
          </>
        )}
      />

      <Modal
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        destroyOnClose
      >
        <ProjectDetailsFormContainer modalView={true} cancelModalView={() => setIsOpen(false)}/>
      </Modal>
    </>
  )
}

export default ProjectSelect
