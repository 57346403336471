import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import lodash from 'lodash'
import axios from './../../helpers/axiosHelper'
import StakeholderAttributesPage from './StakeholderAttributesPage'
import { getOrganizations } from '../../store/organizationsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { editStakeholder, getStakeholders } from '../../store/stakeholdersReducer'
import stakeholderFormReducer, { clearForm, setFormData } from '../../store/stakeholderFormReducer'
import { getProjectsByPeriod } from '../../store/projectsReducer'
import { getCurrentUserRoles } from '../../helpers/localStorage'
import {Navigate} from "react-router";

const StakeholderAttributesPageContainer = (props) => {
  const dispatch = useDispatch()
  const [isDataSent, setIsDataSent] = useState(false)
  const [error, setError] = useState(null)
  const [fieldValuesWithId, setFieldValuesWithId] = useState(null)
  const organizations = useSelector(state => state.organizationsReducer.organizations)
  const stakeholders = useSelector(state => state.stakeholdersReducer.stakeholders)
  const userId = useSelector(store => store.authReducer.userId)
  const orgId = useSelector(store => store.stakeholderFormReducer.orgId)
  const {id} = useParams()

  useEffect(() => {
    if(!!userId) {
      dispatch(getOrganizations())
      dispatch(getProjectsByPeriod(null, false, userId))
      dispatch(getStakeholders())
    }
  }, [dispatch, userId])

  // set initial values if editing 
  useEffect(() => {
    if (id && !!stakeholders?.length) {
      const selectedStakeholder = stakeholders.find(stk => stk.stakeholderId === Number(id))
      const getCount = (data) => {
        if (!data?.length) {
          return [1]
        } else {
          const count = data?.map((item, index) => index + 1)
          return count
        }
      }
      const createInitialValues = () => {
        const phoneTemplate = {
          comment: '',
          isDefault: true,
          phone: '',
          phoneType: 'business',
        }
        const emailTemplate = {
          comment: '',
          email: '',
          emailType: null,
          isDefault: true,
        }
        const additionData = {
          avatar: null,
          officeAddress: "",
          officeLocation: selectedStakeholder.officeLocation,
          organizationShortName: "",
          projectRelations: selectedStakeholder.projects,
          tags: "",
        }
        const firstNameKnownAs = selectedStakeholder.firstNameKnownAs || ''
        const phones = !!selectedStakeholder.phones.length ? selectedStakeholder.phones : [phoneTemplate]
        const emails = !!selectedStakeholder.emails.length ? selectedStakeholder.emails : [emailTemplate]
        return {...selectedStakeholder, ...additionData, phones, emails, firstNameKnownAs}
      }
      setFieldValuesWithId(createInitialValues())
      dispatch(setFormData(createInitialValues(), getCount(selectedStakeholder?.emails), getCount(selectedStakeholder?.phones)))
    }
  }, [id, stakeholders, dispatch])

  useEffect(() => {
    return () => {
      dispatch(clearForm())
    }
  }, [dispatch])


  console.log(fieldValuesWithId)

  const sendStakeholderData = (stakeholderData) => {
    const keysIgnoreWhenSanding = ['officeAddress', 'avatar', 'organizationShortName', 'projectRelations', 'stkId', 'projects']
    const projectsKeysIgnoreWhenSanding = ['projectName']
    
    const stakeholderDataForSending = lodash.omitBy(stakeholderData, (_, key) => keysIgnoreWhenSanding.includes(key))
    const projectsDataForSending = stakeholderData.projectRelations?.map(project => {
      return lodash.omitBy(project, (_, key) => projectsKeysIgnoreWhenSanding.includes(key))
    })
    const phones = stakeholderData.phones.length === 1 && stakeholderData.phones[0].phone === '' ? [] : stakeholderData.phones
    const emails = stakeholderData.emails.length === 1 && stakeholderData.emails[0].email === '' ? [] : stakeholderData.emails

    const dataForSending = {...stakeholderDataForSending, projectRelations: projectsDataForSending, phones, emails}

    if (!!fieldValuesWithId || !!id) {
      const stkId = id ? id : fieldValuesWithId.stkId
      const stakeholder = stakeholders.find(stk => stk.stakeholderId === Number(stkId))
      let removedProjects = stakeholder?.projects?.filter(proj => {
        return !dataForSending.projectRelations?.some(project => project.projId === proj.projId)
      })
      dispatch(editStakeholder(stkId, dataForSending, removedProjects))
        .then(resp => {
          const emails = dataForSending.emails.length === 0 ? [{isDefault: true, email: ''}] : resp.data.emails
          const phones = dataForSending.phones.length === 0 ? [{phoneType: 'business', isDefault: true, phone: ''}] : resp.data.phones
          setFieldValuesWithId(
            { ...resp?.data, 
              organizationShortName: stakeholderData.organizationShortName,
              phones, 
              emails
            })
        })
        .then(() => {
          setIsDataSent(true)
          dispatch(getStakeholders())
        })
        .catch(error => setError(error))
    } else {
      axios.post('/stakeholders', 
        {...dataForSending, empType: dataForSending.empType === 'none' ? '' : dataForSending.empType}
      )
        .then(resp => {
          const emails = dataForSending.emails.length === 0 ? [{isDefault: true, email: ''}] : resp.data.emails
          const phones = dataForSending.phones.length === 0 ? [{phoneType: 'business', isDefault: true, phone: ''}] : resp.data.phones
          setFieldValuesWithId({
            ...resp?.data, 
            organizationShortName: stakeholderData.organizationShortName,
            phones, 
            emails
          })
        })
        .then(() => {
          setIsDataSent(true)
          dispatch(getStakeholders())
        })
        .catch(error => setError(error))
    }
  }

  const currentUserRoles = getCurrentUserRoles()
  const isUser = currentUserRoles.length === 1 && currentUserRoles?.some(role => role.code.toLowerCase() === 'user')
  if (!!fieldValuesWithId?.isSensitiveContact && !!isUser) {
    return <Navigate to='/contacts' />
  }

  return (
    <StakeholderAttributesPage
      error={error}
      parentPage={props.parentPage}
      setError={setError}
      sendStakeholderData={sendStakeholderData}
      isDataSent={isDataSent}
      setIsDataSent={setIsDataSent}
      fieldValuesWithId={fieldValuesWithId}
      setFieldValuesWithId={setFieldValuesWithId}
      organizations={organizations}
      id={id}
    />
  )
}

export default StakeholderAttributesPageContainer
