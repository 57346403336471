import React, {useEffect} from 'react'
import {Form, Formik} from 'formik'
import {format} from 'date-fns'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import * as Yup from 'yup'
import classes from './CustomerDetails.module.css'
import {PrimaryButton, SecondaryButton, UploadButton} from '../../common/buttons/buttons'
import {Input} from '../../common/Input/Input'
import DatePicker from '../../common/DatePicker/DatePicker'
import {Upload, message} from 'antd'
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {changeAddTenantFormValues} from "../../../store/formValuesReducer";


const MainDataForm = ({
                          formRef,
                          dataForEditing,
                          updateCustomer,
                          saveCustomer,
                          isDataSent,
                          setIsDataSent,
                          newCustomerId
                      }) => {
    let setFormValues = () => {
    }
    const today = format(new Date(), 'yyyy-MM-dd 00:00:00')
    const dispatch = useDispatch()
    const activeTillDefault = moment.utc('31.12.2099', 'DD.MM.YYYY').format()
    let initialValues = {
        name: '',
        activeFrom: today,
        activeTill: activeTillDefault,
        logo: ''
    }

    useEffect(() => {
        setFormValues('name', dataForEditing?.name || '')
        setFormValues('activeFrom', dataForEditing?.activeFrom || today)
        setFormValues('activeTill', dataForEditing?.activeTill || activeTillDefault)
        setFormValues('logo', dataForEditing?.logo || '')
    }, [dataForEditing, today, activeTillDefault])

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('Tenant is required'),
        activeFrom: Yup.string()
            .required('Active from is required'),
    })

    const onSubmit = (formikData) => {
        const activeFrom = moment.utc(formikData.activeFrom)
        const activeTill = formikData.activeTill === null || formikData.activeTill === "Invalid date" ? moment.utc('2099-12-31T00:00:00') : moment.utc(formikData.activeTill)

        if(formikData.activeFrom !== 'Invalid date'){
          const formData = new FormData()
          !!formikData.logo && typeof formikData.logo !== 'string' && formData.append("logo", formikData.logo.originFileObj)
          if (formikData.logo === '') {
            formData.append("customer", new Blob([JSON.stringify({
              activeFrom,
              activeTill,
              name: formikData.name,
              deleteLogo: true
            }, null, 2)], {type: 'application/json'}))
          } else {
            formData.append("customer", new Blob([JSON.stringify({
              activeFrom,
              activeTill,
              name: formikData.name,
            }, null, 2)], {type: 'application/json'}))
          }

          dataForEditing
              ? updateCustomer(dataForEditing?.custId, formData, formikData.name)
              : saveCustomer(formData)
        }
        else {

        }

    }

    function beforeUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) message.error('File must be less than 2 mb')
        return isLt2M ? true : Upload.LIST_IGNORE
    }


    return (
        <>
            <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit}
                    validationSchema={validationSchema}>
                {({setFieldValue, values, errors, touched, setFieldError}) => {
                    setFormValues = setFieldValue
                    if (!!errors.name && !!values.name) {
                        setFieldError('name', '')
                    }

                    function getPreviousDate(dateString) {
                        const date = new Date(dateString);
                        if (isNaN(date.getTime())) {
                            return '';
                        }
                        date.setDate(date.getDate() + 1);
                        return date.toISOString().split('T')[0] + 'T' + date.toISOString().split('T')[1];
                    }

                    const onNameChange = (value, inputName) => {
                        setFormValues('name', value)
                        dispatch(changeAddTenantFormValues(value, inputName))
                    }

                    const onChangeLogo = (logo, inputName) => {
                        setFieldValue(inputName, logo.fileList[0])
                        dispatch(changeAddTenantFormValues(logo.fileList[0], inputName))
                    }

                    const onRemoveLogo = () => {
                        setFieldValue("logo", '')
                        dispatch(changeAddTenantFormValues('', "logo"))
                    }


                    const onChangeActiveFrom = (value) => {


                        setFieldValue('activeFrom', moment(value).format('YYYY-MM-DDT00:00:00'));
                        // dispatch(changeAddTenantFormValues(dataForEditing?.activeTill || activeTillDefault, "activeTill"));
                        // dispatch(changeAddTenantFormValues(moment(value).format('YYYY-MM-DDT00:00:00'), "activeFrom"));


                    }


                    const onChangeActiveTill = (value) => {

                        if (moment(value).isValid()) {
                            setFieldValue('activeTill', moment(value).format('YYYY-MM-DDT00:00:00'))
                        }
                        else {
                            setFieldValue('activeTill', null)
                        }

                        // dispatch(changeAddTenantFormValues(dataForEditing?.activeFrom || today, "activeFrom"))
                        // dispatch(changeAddTenantFormValues(moment(value).format('YYYY-MM-DDT00:00:00'), "activeTill"))

                    }



                    return (
                        <Form className={classes.form}>
                            <div className={classes.inputsArea}>
                                <Input
                                    name='name'
                                    label={'Tenant*'}
                                    placeholder='Tenant'
                                    className='autoWidthInput'
                                    InputProps={{
                                        onChange: (e) => onNameChange(e.target.value, 'name')
                                    }}
                                />
                                <div className={classes.datePickerArea}>
                                    <DatePicker
                                        name='activeFrom'
                                        label={'Active From*'}
                                        value={values.activeFrom}
                                        onChange={(value) => setFieldValue('activeFrom', moment(value).format('YYYY-MM-DDT00:00:00'))}
                                    />
                                    <DatePicker
                                        name='activeTill'
                                        label='Active Till'
                                        value={moment.utc(values.activeTill).isSame(moment(activeTillDefault)) || values.activeTill === 'Invalid date' ? null : values.activeTill}
                                        onChange={(value) => setFieldValue('activeTill', moment.utc(value).format('YYYY-MM-DDT00:00:00'))}
                                        minDate={getPreviousDate(values.activeFrom)}
                                    />
                                </div>
                            </div>
                            <div className={classes.logoArea}>
                                <UploadButton
                                    beforeUpload={beforeUpload}
                                    onChange={(event) => onChangeLogo(event, 'logo')}
                                    name={'logo'}
                                    accept={"image/png, image/jpeg"}
                                    maxCount={1}
                                    imgUrl={values.logo}
                                    onRemove={onRemoveLogo}
                                    btnName='Logo'
                                    size='140px'
                                    showUploadList={false}
                                    isDragger={true}
                                />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            {isDataSent &&
                <SuccessModal
                    isDataSent={isDataSent}
                    setIsDataSent={setIsDataSent}
                    newCustomerId={newCustomerId}
                    dataForEditing={dataForEditing}
                />
            }
        </>
    )
}

const SuccessModal = (props) => {
    const navigate = useNavigate()
    const onOk = () => {
        props.setIsDataSent(false)
        navigate('/Tenants')
    }
    const onAddUsers = () => {
        props.setIsDataSent(false)
        navigate(`/Tenants/edit/${props.newCustomerId}`)
    }

    return (
        <Dialog
            open={props.isDataSent}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent className={classes.successMessageModal}>
                <div className={classes.successMessage}>
                    All changes have been saved
                </div>
                <div className={classes.successMessageButtons}>
                    {props.dataForEditing ? (
                        <PrimaryButton
                            text='OK'
                            onClick={onOk}
                            style={{width: '130px', height: '40px', marginLeft: '25px'}}
                        />
                    ) : (
                        <>
                            <PrimaryButton
                                text='CLOSE'
                                onClick={onOk}
                                style={{width: '130px', height: '40px', marginLeft: '25px'}}
                            />
                            <SecondaryButton
                                text='ADD USERS'
                                style={{width: '130px', height: '40px', marginLeft: '25px'}}
                                onClick={onAddUsers}
                            />
                        </>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default MainDataForm
