import { message, Modal, Table } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { addStakeholdersToProjectTC } from '../../../store/projectsReducer'
import { PrimaryButton, SecondaryButton } from '../../common/buttons/buttons'
import Search from '../../common/Search/Search'

const AddContactsModal = ({isOpen, handleClose, isStakeholdersLoading}) => {
  const dispatch = useDispatch()
  const {projId} = useParams()
  const stakeholders = useSelector(state => state.stakeholdersReducer.stakeholders)
  const organizations = useSelector(state => state.organizationsReducer.organizations)
  const projectStakeholders = useSelector(state => state.projectsReducer.projectStakeholders)
  
  const [contactOptions, setContactOptions] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedContacts, setSelectedContacts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const availableStakeholders = stakeholders.filter(stk => {
      return !projectStakeholders?.some(prStk => prStk.stakeholderId === stk.stakeholderId)
    })
    if (!!searchQuery.length) {
      setContactOptions(availableStakeholders.filter(stk => stk.fullName.toLowerCase().includes(searchQuery.toLowerCase())))
    } else {
      setContactOptions(availableStakeholders)
    }
    return () => {setContactOptions([])}
  }, [stakeholders, searchQuery, projectStakeholders])

  const addContacts = () => {
    const addedStakeholders = stakeholders.filter(stk => selectedContacts.includes(stk.stakeholderId))
    const data = addedStakeholders.map(stk => ({
      stakeholderId: stk.stakeholderId,
      projectRelations: [
        {
          influenceLvl: null,
          interestLvl: null,
          isDisrupter: null,
          isProjectTeamMember: true,
          projId: projId,
          roleDescription: '',
          roleTitle: '',
          supportLvl: null,
          team: '',
          workloadPct: null
        }
      ],
    }))
    setIsLoading(true)
    dispatch(addStakeholdersToProjectTC(+projId, data))
      .then((response) => {
        if (response?.status === 200) {
          handleClose()
          message.success(data.length === 1 ? 'Contact added!' : 'Contacts added!', 2)
        }
        setIsLoading(false)
      })
  }

  return (
    <Modal
      title={<span style={{fontWeight: 600}}>ADD CONTACTS</span>}
      visible={isOpen}
      footer={false}
      onCancel={handleClose}
      width={'700px'}
    >
      <Search onChange={event => setSearchQuery(event.target.value)} />
      <div
        style={{
          height: '300px',
          marginTop: '15px',
          marginBottom: '20px',
          overflow: 'auto',
        }}
      >
        <Table
          columns={getColumns(organizations)}
          dataSource={contactOptions || []}
          rowKey={(record) => record?.stakeholderId}
          loading={isStakeholdersLoading}
          pagination={false}

          rowSelection={{
            onChange: (ids,is, b, c) => {
              setSelectedContacts(ids)
            },
            preserveSelectedRowKeys: true
          }}
        />
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <SecondaryButton
          onClick={() => handleClose()}
          style={{width: '100px', marginRight: '10px'}}
          text='CANCEL'
        />
        <PrimaryButton
          onClick={() => addContacts()}
          style={{width: '100px'}}
          text='ADD'
          loading={isLoading}
        />
      </div>
    </Modal>
  )
}

const getColumns = (organizations) => {
  return ([
    {
      dataIndex: 'fullName',
      title: 'Full Name',
      key: 'fullName',
      sorter: (stk1, stk2) => stk1.fullName.toLowerCase().localeCompare(stk2.fullName.toLowerCase())
    },
    {
      dataIndex: 'orgId',
      title: 'Organisation',
      key: 'orgId',
      render: (params) => {
        const orgName = organizations.find(o => o.orgId === params)?.name
        return (
          <div>
            {orgName}
          </div>
        )
      },
      sorter: (stk1, stk2) => {
        const orgName1 = organizations.find(o => o.orgId === stk1.orgId)?.name
        const orgName2 = organizations.find(o => o.orgId === stk2.orgId)?.name
        return orgName1?.toLowerCase()?.localeCompare(orgName2?.toLowerCase())
      }
    },
    {
      dataIndex: 'empType',
      title: 'Contact Type',
      key: 'empType',
      sorter: (stk1, stk2) => {
        return (stk1?.empType?.toLowerCase() || '')?.localeCompare((stk2?.empType?.toLowerCase() || ''))
      }
    },
    {
      dataIndex: 'jobTitle',
      title: 'Job Title',
      key: 'jobTitle',
      sorter: (stk1, stk2) => {
        return (stk1?.jobTitle?.toLowerCase() || '')?.localeCompare((stk2?.jobTitle?.toLowerCase() || ''))
      }
    },
  ])
}

export default AddContactsModal
