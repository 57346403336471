import React, { useEffect } from 'react'
import {Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import { SwatchesPicker} from 'react-color'
import classes from './OrganizationModal.module.css'
import { PrimaryButton, SecondaryButton } from '../../common/buttons/buttons'
import {Input, StyledInput} from '../../common/Input/Input'
import Checkbox from '../../common/Checkbox/Checkbox'

const OrganizationModalForm = (props) => {
  let setFieldValueFunc = () => {}
  const initialValues = {
    name: props.organizationModalData.organizationData?.name || '',
    nickname: props.organizationModalData.organizationData?.nickname || '',
    location: props.organizationModalData.organizationData?.location || '',
    address: props.organizationModalData.organizationData?.address || '',
    isActive: props.organizationModalData.organizationData?.isActive || true,
    color: props.organizationModalData.organizationData?.color || '#959595',
  }

  useEffect(() => {
    setFieldValueFunc('isActive', props.organizationModalData.organizationData?.isActive || true)
  }, [props.organizationModalData.organizationData])

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Organisation name is required'),
    nickname: Yup.string()
      .required('Organisation short name is required'),
  })

  const onSubmit = (formData) => {
    if(props.organizationModalData.organizationData) {
      const updatedOrganization = {...props.organizationModalData.organizationData, ...formData}
      props.updateOrganization(updatedOrganization)
    } else {
      const newOrganizationData = {...formData, tags: ''}
      props.saveOrganization(newOrganizationData)
    }
  }

  const generateNickname = (value, setFieldValue) => { 
    setFieldValue('name', value)
    setFieldValue('nickname', value)
  }
  
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({values, setFieldValue}) => {  
        setFieldValueFunc = setFieldValue
        if (props.error?.duplicatedName && props.error.duplicatedName !== values.name) props.setError(null)
        if (props.error?.duplicatedNickname && props.error.duplicatedNickname !== values.nickname) props.setError(null)
        return (
          <Form className={classes.form}>
            <div className={classes.inputsRow}>
              <Field
                InputLabelProps={ { shrink: true } }
                component={StyledInput}    
                onChange={(event) => generateNickname(event.target.value, setFieldValue)}
                label='Organisation Name*'
                name='name'
                placeholder='Enter organisation name'
                variant='outlined'
                InputProps={{classes:{root:'autoWidthInput'}}}
                style={{width:'329px'}}
                disabled={false} 
                autoComplete='off'
              />
              <Input 
                name='nickname'
                label='Organisation Short Name*'
                placeholder='Enter organisation short name'
              />
            </div>
            <div className={classes.inputsRow}>
              <Input 
                name='location'
                label='Location'
                placeholder='Enter location'
              />
              <Input 
                name='address'
                label='Address'
                placeholder='Enter address'
              />
            </div>
            <span className={classes.colorPickerLabel}>
              ORGANISATION COLOR
            </span>
            <SwatchesPicker 
              className={classes.colorPicker}
              color={values.color}
              onChange={(val)  => setFieldValue('color', val.hex)}
              width={'550px'}
              height={'300px'}
            />
            <Checkbox
              checked={values.isActive}
              disabled={!props.organizationModalData.organizationData}
              label='Active organisation'
              onChange={() => setFieldValue('isActive', !values.isActive)}
            />
            <div className={classes.buttonsArea}>
              <SecondaryButton
                onClick={() => props.setOrganizationModalData({isOpen: false})}
                style={{width:'100px', height: 'auto'}}
                text='Cancel' 
              />
              <PrimaryButton 
                htmlType='submit' 
                style={{width:'100px', height: 'auto', marginLeft: '15px'}}
                text='SAVE' 
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OrganizationModalForm
