import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {deactivateUser, getSystemAdminsList, getUserList} from '../../store/usersReducer'
import {
    getAdminsListFilter,
    getAdminsSearchString,
    getCurrentUserRoles,
    setAdminsListFilter
} from '../../helpers/localStorage'
import {Navigate} from "react-router";
import Admins from "./Admins";

const AdminsContainer = () => {
    const dispatch = useDispatch()
    const admins = useSelector(state => state.usersReducer.adminsList)
    const isSignedIn = useSelector(state => state.authReducer.isSignedIn)
    const [modalData, setModalData] = useState({isOpen: false})
    const [selectedUserType, setSelectedUserType] = useState(getAdminsListFilter())
    const searchedValue =  getAdminsSearchString()
    const userRole = getCurrentUserRoles()
    let tableRows = admins;
    useEffect(() => {
        if (userRole && userRole[0].code === "system admin") {
            if(!searchedValue || searchedValue === ''){
                dispatch(getSystemAdminsList({
                    filter_request: {
                        search_value: "system_admin"
                    },
                }))
            }
           else{
                dispatch(getSystemAdminsList({
                    filter_request: {
                        search_value: searchedValue
                    }
                }, true))
            }
        }
        else {
            if (!searchedValue) {
                dispatch(getUserList())
            }
        }
    }, [])


        if (userRole[0].code === "system admin") {
            if (selectedUserType === 'All') {
                console.log(selectedUserType)
                tableRows = admins
            } else {
                console.log(selectedUserType)
                tableRows = admins.filter(user => selectedUserType === 'Active' ? user.isActive : !user.isActive)
            }
        } else {
            if (!searchedValue) {
                tableRows = admins
            }
        }



    const search = (value) => {
        if (userRole && userRole[0].code === "system admin") {
            dispatch(getSystemAdminsList({
                filter_request: {
                    search_value: value
                }
            }, true))
        } else {
            let searchResults
            if (selectedUserType === 'All'){
                searchResults = admins
                    .filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().includes(value.trim().toLowerCase()))
            }
            else{
                searchResults = admins
                    .filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().includes(value.trim().toLowerCase()))
                    .filter(user => selectedUserType === 'Active' ? user.isActive : !user.isActive)
            }

            if (!value) {
                tableRows = admins
            }
            tableRows = searchResults
        }

    }

    const selectUserType = (val) => {

        // if (val === 'All') {
        //     setTableRows(admins)
        // } else {
        //     setTableRows(admins.filter(user => val === 'Active' ? user.isActive : !user.isActive))
        // }
        setSelectedUserType(val)
        setAdminsListFilter(val)
    }

    const removeUser = (userId, deleteStakeholder) => {
        dispatch(deactivateUser(userId, deleteStakeholder))
            .then(() => {
                if (searchedValue && searchedValue !== '') {
                    dispatch(getSystemAdminsList({
                        filter_request: {
                            search_value: searchedValue.trim()
                        }
                    }, true))
                } else {
                    dispatch(getSystemAdminsList({
                        filter_request: {
                            search_value: "system_admin"
                        },

                    }))
                }

            })
    }

    const currentUserRoles = getCurrentUserRoles()
    const isAdmin = currentUserRoles == null ? false : currentUserRoles?.some(role => role.code.toLowerCase() === "system admin" || role.code.toLowerCase() === "customer admin")

    const userTypeOptions = [
        {id: 1, name: 'All', value: 'All'},
        {id: 2, name: 'Active', value: 'Active'},
        {id: 3, name: 'Inactive', value: 'Inactive'},
    ]

    if (!isAdmin && isSignedIn) {
        return <Navigate to='/StakeholderMap'/>
    }

    return (
        <Admins
            removeUser={removeUser}
            modalData={modalData}
            search={search}
            setModalData={setModalData}
            tableRows={tableRows}
            selectedUserType={selectedUserType}
            selectUserType={selectUserType}
            userTypeOptions={userTypeOptions}
            searchedValue={searchedValue}
        />
    )
}

export default AdminsContainer
