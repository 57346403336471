import React, {useEffect, useState} from 'react'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import classes from './UsersTable.module.css'
import {PrimaryButton, SecondaryButton} from '../../common/buttons/buttons'
import {Input} from '../../common/Input/Input'
import Checkbox from './../../common/Checkbox/Checkbox'
import generatePassword from '../../../helpers/passwordGenerator'
import {
    getAllowedUserRoles,
    getUserListWithPaginationForEmailSelection, setIsUserAdding,
    updateUserRoles
} from "../../../store/usersReducer";
import {useDispatch, useSelector} from "react-redux";
import {AutoComplete} from "antd";
import {getCustomers, tenantsModalEditMode} from "../../../store/customersReducer";
import axios from "../../../helpers/axiosHelper";
import {Popconfirm} from 'antd';


const UserModalForm = ({
                           modalData,
                           editUser,
                           addUser,
                           setUsersModalData,
                           roleOptions,
                           isAdminsTable,
                           isTenantsTable,
                           isUsersTable,
                           setModalData,
                       }) => {

    const [isNewPassword, setIsNewPassword] = useState(false)
    const dispatch = useDispatch()
    const userId = useSelector(state => state.authReducer.userId)
    const usersForEmail = useSelector(state => state.usersReducer.usersForEmail)
    const isUserAdding = useSelector(state => state.usersReducer.isAddingUser)
    const [disabledFields, setDisabledFields] = useState(false)
    const tenantsEditMode = useSelector(state => state.customersReducer.editMode)
    const customers = useSelector(state => state.customersReducer.defaultCustomers)
    const [isValidEmailValue, setIsValidEmailValue] = useState(true)
    const [isUserExists, setIsUserExists] = useState(false)
    let currentTenantsInUser
    if (tenantsEditMode) {
        currentTenantsInUser = usersForEmail.find((u) => u.firstName === modalData.userData.firstName && u.lastName === modalData.userData.lastName)
    }
    useEffect(() => {
        dispatch(getCustomers())
        dispatch(getAllowedUserRoles())
    }, [dispatch])


    const initialValues = {
        firstName: modalData.userData?.firstName || '',
        lastName: modalData.userData?.lastName || '',
        email: modalData.userData?.email || '',
        old_email: modalData.userData?.email || '',
        phoneNumber: modalData.userData?.phoneNumber || '',
        password: modalData.userData?.firstName ? '*****' : '',
        roles: isAdminsTable
            ? (roleOptions.filter((r) => r.code === "system admin").length > 0
                ? roleOptions.filter((r) => r.code === "system admin")
                : [{
                    id: 8,
                    code: "system admin",
                    name: "manage system admins and manage customers"
                }])
            : modalData.userData?.roles || [],
        isActive: modalData.userData?.isActive !== undefined ? modalData.userData?.isActive : true
    }

    const validationSchema = Yup.object().shape({
        firstName: !disabledFields && Yup.string().required('This field is required'),
        lastName: !disabledFields && Yup.string().required('This field is required'),
        email: isTenantsTable && isUserAdding ? null : Yup.string()
            .email('Invalid email')
            .required('This field is required'),
        password: Yup.string()
            .test('no-spaces', 'Password cannot contain spaces', value => !/\s/.test(value))
            .required('This field is required'),
        roles: isTenantsTable ? Yup.array()
            .min(1, 'At least one role is required')
            .required('This field is required')
            : null
    });


    useEffect(() => {
        dispatch(getCustomers('ALL'))
    }, [dispatch])

    const onSubmit = (formData) => {

        if (modalData.userData) {
            if (isUsersTable) {
                dispatch(updateUserRoles(modalData.userData.tenants.map((tenant) => {
                    return {
                        user_id: tenant.user_id,
                        cust_id: tenant.cust_id,
                        roles: tenant.roles.map((t) => t.id).flat(1),
                        is_active: !modalData.userData.tenants.length ? false : tenant.is_active
                    }
                })))
                    .then(() => {
                        editUser(
                            {
                                ...formData,
                                password: formData.password === '*****' ? null : formData.password,
                                email: formData.email === '' ? null : formData.email,
                                old_email: modalData.userData?.email || '',
                                roles: modalData.userData.tenants.map((tenant) => tenant.roles.filter(role => Object.keys(role).length !== 0)).flat(1),
                            },
                            modalData.userData.tenants[0].user_id,
                            modalData.userData.tenants[0].cust_id,
                        )
                    })

            } else {
                editUser(
                    {
                        ...formData,
                        password: formData.password === '*****' ? null : formData.password,
                        email: formData.email === '' ? null : formData.email
                    },
                    modalData.userData.userId
                )
            }
        } else {
            addUser({...formData, password: formData.password === '*****' ? null : formData.password})
        }

        setIsUserExists(false)
    }

    const onRoleChange = (cust_id, role_id, checked, role, isActive, isActiveCheckboxClicked) => {
        setModalData(prevData => {
            const updatedTenants = prevData.userData.tenants.map(tenant => {
                if (tenant.cust_id === cust_id) {
                    const updatedRoles = checked
                        ? [...tenant.roles, role]
                        : tenant.roles.filter(role => role.id !== role_id)

                    return {
                        ...tenant,
                        is_active: isActiveCheckboxClicked ? !isActive : isActive,
                        roles: updatedRoles
                    }
                }
                return tenant
            })

            return {
                ...prevData,
                userData: {
                    ...prevData.userData,
                    tenants: updatedTenants
                }
            }
        })
    }

    useEffect(() => {
        if (isTenantsTable) {
            dispatch(getUserListWithPaginationForEmailSelection({
                "fields": [
                    {
                        "field_name": "email",
                        "operator": "CONTAINS_IGNORE_CASE",
                        "search_value": ''
                    }
                ]
            }, false, true))
        }

    }, [dispatch, isTenantsTable])


    const usersList = usersForEmail.map((user, index) => ({
        value: user.email,
        label: user.email,
        key: index,
    }))

    const [options, setOptions] = useState(usersList);


    const onCloseModal = () => {
        dispatch(setIsUserAdding(false))
        dispatch(tenantsModalEditMode(true))
        setUsersModalData({isOpen: false})
    }
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }
    const confirm = (submitForm) => {
        if (isValidEmailValue) {
            submitForm()
        }
    }
    const cancel = () => {

    }
    useEffect(() => {
        const customersFullNames = customers.map(customer => `${customer.firstName}-${customer.lastName}`);

        const filteredUsers = usersForEmail.filter(user => {
            const hasUserRole = user.tenants.some(tenant =>
                tenant.roles.some(role => (role.code === 'user' || role.code === 'project admin') && role.code !== 'system admin')
            );
            const hasEmptyRoles = user.tenants.some(tenant => tenant.roles.length === 0);
            const isNotCustomer = !customersFullNames.includes(`${user.firstName}-${user.lastName}`);

            return (hasUserRole || hasEmptyRoles) && isNotCustomer;
        });

        setOptions(
            filteredUsers.map((user, index) => ({
                value: user.email,
                label: user.email,
                key: index,
            }))
        );
    }, [usersForEmail, customers]);

    return (
        <Formik initialValues={initialValues}     validateOnChange={false}
                validateOnBlur={false} onSubmit={onSubmit} validationSchema={validationSchema} onSubmit={(values, { setSubmitting, validateForm }, ) => {
            validateForm().then((errors) => {
                if (Object.keys(errors).length === 0) {
                    onSubmit(values);
                } else {
                }
                setSubmitting(false);
            });
        }}>
            {({setFieldValue, values, errors, submitForm, touched, setFieldError }) => {
                const customersFullNames = customers.map(customer => `${customer.firstName}-${customer.lastName}`)

                const filteredUsers = usersForEmail.filter(user => {
                    const hasUserRole = user.tenants.some(tenant =>
                        tenant.roles.some(role => (role.code === 'user' || role.code === 'project admin') && role.code !== 'system admin')
                    );
                    const hasEmptyRoles = user.tenants.some(tenant => tenant.roles.length === 0);
                    const isNotCustomer = !customersFullNames.includes(`${user.firstName}-${user.lastName}`)

                    return (hasUserRole || hasEmptyRoles) && isNotCustomer
                })


                const onPasswordChange = (e) => {
                    setIsNewPassword(true)
                    setFieldValue('password', e.target.value)
                    setFieldError('password', undefined)
                }

                const onChangeSelectValue = (value) => {
                    setFieldValue('email', value)
                    setFieldError('email', undefined)
                    if (validateEmail(value)) {
                        setIsValidEmailValue(true)
                    } else {
                        setIsValidEmailValue(false)
                    }
                    const isExistingUser = filteredUsers.find((u) => u.email === value)

                    if (isExistingUser) {
                        axios.get(`/system-admin/customers/${isExistingUser.tenants[0].cust_id}/users`)
                            .then((response) => {
                                const matchingUser = response.data.users.find((u) => u.email === value)
                                setFieldValue('firstName', matchingUser.firstName)
                                setFieldValue('lastName', matchingUser.lastName)
                                setFieldValue('phoneNumber', matchingUser.phoneNumber)
                                setFieldValue('password','*****')
                                setDisabledFields(true)
                                setIsUserExists(true)
                            })
                    }
                }
                const handleSearch = (value) => {
                    setDisabledFields(false)
                    setFieldValue('email', value)
                    if (value) {
                        const filteredOptions = filteredUsers
                            .filter(user => user.email.includes(value))
                            .map((user, index) => ({
                                value: user.email,
                                label: user.email,
                                key: index,
                            }));
                        setOptions(filteredOptions);
                    } else {
                        setOptions(filteredUsers
                            .map((user, index) => ({
                                value: user.email,
                                label: user.email,
                                key: index,
                            })));
                    }
                    if (validateEmail(value)) {
                        setIsValidEmailValue(true)
                    } else {
                        setIsValidEmailValue(false)
                    }
                };

                const onEmailChange = (e) => {
                    setFieldValue('email', e.target.value)
                    setDisabledFields(false)
                    setFieldError('email', undefined)
                }


                const handleFieldChange = (e) => {
                    const { name, value } = e.target;
                    // Clear the error for the field being changed
                    setFieldError(name, undefined);
                    setFieldValue(name, value)
                };

                return (
                    <Form className={classes.form}>
                        <div className={classes.inputsPair}>
                            {
                                isTenantsTable && isUserAdding
                                    ?
                                    <div className={'autocomplete'}>
                                        <label
                                            className={!isValidEmailValue ? 'autocompleteLabelError' : 'autocompleteLabel'}
                                            htmlFor={'email'}>Email*</label>
                                        <AutoComplete
                                            name='email'
                                            label='Email*'
                                            id={'email'}
                                            options={options}
                                            style={{
                                                width: 200,
                                                borderColor: isValidEmailValue ? '#000 !important' : '#f44336 !important'
                                            }}
                                            onSelect={onChangeSelectValue}
                                            onSearch={handleSearch}
                                            // onChange={(e) => onEmailChange(e)}
                                            placeholder='Email'
                                            className={isValidEmailValue ? 'autocompleteInput' : 'autocompleteInputError'}
                                            showArrow={true}
                                            autoComplete='new-password'
                                        />
                                        {
                                            !isValidEmailValue
                                            &&
                                            <p className={classes.errorEmail}>Invalid email</p>
                                        }
                                    </div>

                                    :
                                    <Input
                                        name='email'
                                        label='Email*'
                                        placeholder='Email'
                                        InputProps={{
                                            onChange: onEmailChange
                                        }}
                                        disabled={isUsersTable && modalData?.userData?.tenants?.length > 1}
                                    />
                            }
                            <div style={{position: 'relative'}}>
                                <Input
                                    name='password'
                                    label='Password*'
                                    disabled={disabledFields || (isUsersTable && modalData?.userData?.tenants?.length > 1)}
                                    placeholder='Password'
                                    InputProps={{
                                        ...(!isNewPassword && {type: 'password'}),
                                        autoComplete: 'new-password',
                                        onChange: onPasswordChange
                                    }}
                                />
                                <div
                                    className={classes.generateBtn}
                                    onClick={(isUsersTable && modalData?.userData?.tenants?.length > 1) || disabledFields ? () => {
                                    } : () => {
                                        setFieldValue('password', generatePassword())
                                        setIsNewPassword(true)
                                        setFieldError('password', undefined)
                                    }}
                                    style={{
                                        cursor: (isUsersTable && modalData?.userData?.tenants?.length > 1) || disabledFields ? 'not-allowed' : 'pointer',
                                        border: (isUsersTable && modalData?.userData?.tenants?.length > 1) || disabledFields ? '2px solid rgba(0, 0, 0, 0.38)' : '2px solid #0E9AFC',
                                        color:  (isUsersTable && modalData?.userData?.tenants?.length > 1) || disabledFields ? 'rgba(0, 0, 0, 0.38)' : '#000',
                                    }}
                                >
                                    Generate
                                </div>
                            </div>
                        </div>

                        <div className={classes.inputsPair}>
                            <Input
                                name='firstName'
                                label='First name*'
                                placeholder='First name'
                                disabled={disabledFields}
                                InputProps={{
                                    onChange: handleFieldChange
                                }}
                            />
                            <Input
                                name='lastName'
                                label='Last name*'
                                placeholder='Last name'
                                disabled={disabledFields}
                                InputProps={{
                                    onChange: handleFieldChange
                                }}
                            />
                        </div>
                        <div className={classes.inputsPair}>
                            <Input
                                name='phoneNumber'
                                label='Phone number'
                                placeholder='Phone number'
                                disabled={disabledFields}
                            />
                            {
                                !isTenantsTable
                                &&
                                <div className={`${classes.rolesWrap} ${errors.roles ? classes.rolesWrapError : ''}`}
                                     style={{
                                         height: '56px',
                                         width: '325px',
                                         justifyContent: 'start',
                                         marginRight: '7px'
                                     }}>
                                    <div className={classes.roleAreaTitle}>
                                        System admin
                                    </div>
                                    {roleOptions.filter((r) => r.code === "system admin").map(role => (
                                        <Checkbox
                                            checked={values?.roles?.some(r => r.id === role?.id) || isAdminsTable || modalData?.userData?.tenants?.some((r) => r.roles.some(r => r.code === "system admin"))}
                                            defaultChecked={role.code === "system admin" || modalData?.userData?.tenants?.some((r) => r.roles.some(r => r.code === "system admin"))}
                                            disabled={isUsersTable || isTenantsTable || isAdminsTable}
                                            label={role?.code.charAt(0).toUpperCase() + role?.code.slice(1)}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setFieldValue('roles', [...values.roles, role])
                                                } else {
                                                    setFieldValue('roles', values.roles.filter(r => r.id !== role?.id))
                                                }
                                            }}
                                        />
                                    ))}
                                </div>
                            }
                        </div>
                        {
                            !isAdminsTable && !isUsersTable
                            &&
                            <>
                                <div className={`${classes.rolesWrap} ${errors.roles ? classes.rolesWrapError : ''}`}>
                                    <div className={classes.roleAreaTitle}>
                                        Roles*
                                    </div>
                                    {roleOptions.filter((r) => r.code !== "system admin").map(role => (
                                        <Checkbox
                                            checked={values.roles?.some(r => r.id === role?.id)}
                                            label={role?.code.charAt(0).toUpperCase() + role?.code.slice(1)}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setFieldValue('roles', [...values.roles, role])
                                                    setFieldError('roles', undefined)
                                                } else {
                                                    setFieldValue('roles', values.roles.filter(r => r.id !== role?.id))
                                                }
                                            }}
                                        />
                                    ))}
                                </div>
                                <div className={classes.rolesErrorText}>
                                    {errors.roles}
                                </div>
                            </>
                        }
                        {
                            !isUsersTable
                            &&
                            <Checkbox
                                checked={values.isActive}
                                label='Active user'
                                disabled={isAdminsTable && modalData && modalData?.userData?.userId === userId ? userId : 0}
                                onChange={() => setFieldValue('isActive', !values.isActive)}
                                editable={false}
                            />
                        }
                        {
                            isUsersTable
                            &&
                            <div className={classes.tenantsTable}>
                                <div className={classes.tenantsTable__header}>
                                    <div className={classes.tenantsTable__column}>Tenant</div>
                                    <div className={classes.tenantsTable__column}>Project Admin</div>
                                    <div className={classes.tenantsTable__column}>User</div>
                                    <div className={classes.tenantsTable__column}>Active</div>
                                </div>
                                <div className={classes.tenantsTable__body}>
                                    {
                                        modalData?.userData?.tenants?.filter((t) => t.cust_name !== 'System').sort((a, b) => {
                                            const nameA = a.cust_name.toLowerCase();
                                            const nameB = b.cust_name.toLowerCase();

                                            if (nameA < nameB) {
                                                return -1;
                                            }
                                            if (nameA > nameB) {
                                                return 1;
                                            }
                                            return 0;
                                        }).map((tenant) => {
                                            return (
                                                <div className={classes.tenantsTable__row}>
                                                    <div
                                                        className={classes.tenantsTable__rowColumn}>{tenant.cust_name}</div>

                                                    {roleOptions.filter((role) => role.code !== "system admin").sort((a, b) => {
                                                        if (a.code === 'project admin') return -1
                                                        if (b.code === 'project admin') return 1
                                                        return 0
                                                    }).map(role => (
                                                        <div className={classes.tenantsTable__rowColumn}>
                                                            <Checkbox
                                                                checked={tenant.roles?.some(r => r.id === role?.id)}
                                                                label={''}
                                                                onChange={(e) => onRoleChange(tenant.cust_id, role?.id, e.target.checked, role, tenant.is_active, false)}
                                                            />
                                                        </div>
                                                    ))}

                                                    <div className={classes.tenantsTable__rowColumn}>
                                                        <Checkbox
                                                            checked={tenant.is_active}
                                                            label=''
                                                            onChange={(e) => onRoleChange(tenant.cust_id, 0, e.target.checked, {}, tenant.is_active, true)}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }

                        <div className={classes.buttonsArea} style={{
                            marginTop: isUsersTable ? '30px' : 'initial'
                        }}>
                            <SecondaryButton
                                onClick={onCloseModal}
                                style={{width: '100px', height: 'auto'}}
                                text='Cancel'
                            />
                            {
                                isTenantsTable && tenantsEditMode && currentTenantsInUser && currentTenantsInUser?.tenants?.length > 1 &&
                                (modalData.userData.firstName !== values.firstName || modalData.userData.lastName !== values.lastName || modalData.userData.email !== values.email || modalData.userData.phoneNumber !== values.phoneNumber)
                                ||
                                (isUsersTable && modalData?.userData?.tenants?.length > 1)
                                &&
                                (modalData.userData.firstName !== values.firstName || modalData.userData.lastName !== values.lastName || modalData.userData.email !== values.email || modalData.userData.phoneNumber !== values.phoneNumber)
                                ||
                                isTenantsTable && (tenantsEditMode && currentTenantsInUser && currentTenantsInUser?.tenants?.length > 1 && isNewPassword)
                                ||
                                (isUsersTable && modalData?.userData?.tenants?.length > 1 && isNewPassword)
                                    ?
                                    <Popconfirm
                                        title="Email, Password, First name, Last name, Phone number will be set for all tenants user belongs to"
                                        onConfirm={() => confirm(submitForm)}
                                        onCancel={cancel}
                                        okText="OK"
                                        cancelText="Cancel"
                                    >
                                        <PrimaryButton
                                            className="yourClassName"
                                            htmlType='button'
                                            style={{width: '100px', height: 'auto', marginLeft: '15px'}}
                                            text='SAVE'
                                        />
                                    </Popconfirm>
                                    :
                                    <PrimaryButton
                                        className="yourClassName"
                                        htmlType='submit'
                                        style={{width: '100px', height: 'auto', marginLeft: '15px'}}
                                        text='SAVE'
                                    />
                            }

                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}


export default UserModalForm
