import React from 'react'
import { Modal} from 'antd'
import classes from './OrganizationModal.module.css'
import OrganizationModalForm from './OrganizationModalForm'

const OrganizationModal = (props) => {
  return (
    <Modal 
      centered
      closable={false}   
      footer={null}
      onCancel={() => props.setOrganizationModalData({isOpen:false})}
      style={{padding: '10px', width: '100%'}}
      visible={true} 
      width={800}
    >
      <div className={classes.modalTitle}>
        {props.organizationModalData.organizationData ? 'EDIT ORGANISATION' : 'NEW ORGANISATION'}
        {props.error && 
          <div className={classes.errorMessage}>
            {props.error.message}
          </div>
        }
      </div>
      <OrganizationModalForm
        error={props.error}
        updateOrganization={props.updateOrganization}
        saveOrganization={props.saveOrganization}
        setOrganizationModalData={props.setOrganizationModalData}
        organizationModalData={props.organizationModalData}
        setError={props.setError}
      />
    </Modal>
  )
}

export default OrganizationModal
