import React from 'react'
import classes from './Projects.module.css'
import {SecondaryButton} from '../../../../common/buttons/buttons'
import AddToProjectModalContainer from './AddToProjectModal/AddToProjectModalContainer'
import ProjectsTable from './ProjectsTable/ProjectsTable'
import CustomDivider from '../../../../common/CustomDivider/CustomDivider'

const Projects = (props) => {
  return (
    <div className={classes.projectsTabWrapper}>
      <CustomDivider text='Projects' textStyle={{width:'70px'}} style={{margin: '0px 0px 20px 0px'}}/>
      <SecondaryButton onClick={() => props.setModalData({isOpen: true})} text='ADD TO PROJECT'/>
      <ProjectsTable
        setModalData={props.setModalData}
        projects={props.projects}
        tableValues={props.tableValues}
        removeProject={props.removeProject}
        values={props.values}
        setHasProjectChanges={props.setHasProjectChanges}
        updatedProjectNumberValue={props.updatedProjectNumberValue}
      />
      
      {props.modalData.isOpen && 
        <AddToProjectModalContainer 
          projects={props.projects}
          modalData={props.modalData}
          setModalData={props.setModalData}
          setFieldValue={props.setFieldValue}
          values={props.values}
          setHasProjectChanges={props.setHasProjectChanges}
        />
      }
    </div>
  )
}

export default Projects
