import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectsByPeriod } from '../../../../../../store/projectsReducer'
import AddToProjectModal from './AddToProjectModal'

const AddToProjectModalContainer = (props) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedProjectPeriod, setSelectedProjectPeriod] = useState('active')
  const dispatch = useDispatch()
  const userId = useSelector(store => store.authReducer.userId)

  useEffect(() => {
    !!userId && dispatch(getProjectsByPeriod(selectedProjectPeriod, false, userId))
  }, [selectedProjectPeriod, dispatch, userId])

  const initialValues = {
    projId: 'none',
    influenceLvl: null,
    interestLvl: null,
    isDisrupter: null,
    roleDescription: '',
    roleTitle: '',
    projectName: '',
    team: '',
    supportLvl: null,
    workloadPct: null,
    isProjectTeamMember: true
  }
  const formValues = props.modalData.editingData ? {...props.modalData.editingData, projId: props.modalData.editingData.id} : initialValues
  const [projectData, setProjectData] = useState(formValues)
  const updateProjectData = (fieldData) => {
    setProjectData({...projectData, ...fieldData})
  }
  const isEditingProject = props.modalData.editingData ? projectData.projId !== props.modalData?.editingData.projId : true
  const isProjectAlreadyAdded = props.values.projectRelations?.some(project => project.projId === projectData.projId) && isEditingProject
  const isProjectNameEmpty = projectData.projId === 'none'
  const isValidationErrors = isProjectAlreadyAdded || isProjectNameEmpty

  useEffect(() => {
    if (!isValidationErrors) {
      setErrorMessage(null)
    }
  }, [projectData.projId, isValidationErrors])

  const closeModal = () => {
    props.setModalData({isOpen: false})
  }

  const validateModalFields = () => {
    if (isProjectAlreadyAdded) {
      setErrorMessage('The person is already added to this project')
    } else if (projectData.projId === 'none') {
      setErrorMessage('Project Name is required!')
    }
  }

  const saveProjectDetails = () => {
    if (isValidationErrors) {
      validateModalFields()
      return
    } else {
      props.setFieldValue(`projectRelations`, [...props.values.projectRelations||[], projectData])
      closeModal()
    }
  }

  const updateProjectDetails = () => {
    if (isValidationErrors) {
      validateModalFields()
      return
    } else {
      const updatedProjects = props.values.projectRelations.map((project => {
        if (project.projId === props.modalData.editingData.projId) {
          delete projectData.id
          return projectData
        } else {
          return project
        }
      }))
      props.setFieldValue('projectRelations', updatedProjects)
      closeModal()
    }
  }

  return (
    <AddToProjectModal 
      closeModal={closeModal}
      errorMessage={errorMessage}
      modalData={props.modalData}
      projects={props.projects}
      projectData={projectData}
      updateProjectData={updateProjectData}  
      updateProjectDetails={updateProjectDetails}    
      setModalData={props.setModalData}
      saveProjectDetails={saveProjectDetails}
      setSelectedProjectPeriod={setSelectedProjectPeriod}
      selectedProjectPeriod={selectedProjectPeriod}
      setHasProjectChanges={props.setHasProjectChanges}
    />
  )
}

export default AddToProjectModalContainer
