import React, {useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import axios from './../../helpers/axiosHelper'
import Login from './Login'
import {
    logIn,
    setCurrentLoginData,
    setCurrentLoginResponseData, setRolesForLogin,
    setTenantsForLogin
} from './../../store/authReducer'
import {Navigate, useNavigate} from "react-router";
import {setCurrentLoginUser} from "../../store/usersReducer";


const LoginContainer = (props) => {
    const [loginError, setLoginError] = useState(null)
    const [isAccessModalOpen, setIsAccessModalOpen] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    if (props.isSignedIn) {
        return <Navigate to='/StakeholderMap'/>
    }

    const signIn = (loginData) => {
        setIsLoading(true)
        axios.post('/users/customers/login', {...loginData})
            .then(response => {
                if (response?.data?.customers?.length > 1) {
                    dispatch(setCurrentLoginData(loginData))
                    dispatch(setTenantsForLogin(response?.data?.customers))
                    dispatch(setCurrentLoginUser(`${response.data.first_name} ${response.data.last_name}`))
                    navigate('/login/choose-tenant')
                } else if (response?.data?.customers?.length <= 1) {
                    axios.post('/users/login', {...loginData, cust_id: response?.data?.customers[0].custId})
                        .then(response => {
                            if (!response.data?.roles?.length) {
                                setLoginError({message: 'This user has no roles'})
                            } else {
                                if(response.data.roles?.length === 1 && response.data.roles.some(role => role.code.toLowerCase() === 'system admin')){
                                    props.logIn({...response?.data, email:loginData.email})
                                    navigate('/Tenants')
                                }
                                else if(response.data.roles?.length >= 1 && response.data.roles.every(role => role.code.toLowerCase() !== 'system admin')){
                                    props.logIn({...response?.data, email:loginData.email})
                                    navigate('/StakeholderMap')
                                }
                                else{
                                    dispatch(setCurrentLoginData(loginData))
                                    dispatch(setRolesForLogin(response?.data?.roles))
                                    dispatch(setCurrentLoginUser(`${response.data.firstName} ${response.data.lastName}`))
                                    navigate('/login/choose-role')
                                    dispatch(setCurrentLoginResponseData(response?.data))
                                    // props.logIn({...response?.data, email: loginData.email})
                                }
                                // props.logIn({...response?.data, email: loginData.email})
                            }
                            setIsLoading(false)
                        })
                        .catch(error => {
                            setIsLoading(false)
                            setLoginError(error?.response?.data)
                        })
                }
            })
            .catch(error => {
                setLoginError(error?.response?.data)
                setIsLoading(false)
            })
    }

    return (
        <Login
            loginError={loginError}
            signIn={signIn}
            isAccessModalOpen={isAccessModalOpen}
            setIsAccessModalOpen={setIsAccessModalOpen}
            isLoading={isLoading}
        />
    )
}

const mapSateToProps = (state) => ({
    isSignedIn: state.authReducer.isSignedIn,
})

export default connect(mapSateToProps, {logIn})(LoginContainer)
